<template>
  <b-modal
    ref="modal-assignment-errors"
    title="Error de importación de archivo"
    centered
    hide-footer
    no-close-on-backdrop
    size="xl"
  >
    <div>
      <b-alert
          variant="danger"
          show
        >
          <h4 class="alert-heading">
            Detalle de errores
          </h4>
          <div class="alert-body">
            <span>Error en la importación de los datos, a continuación puede ver el detalle de los errores.</span>
          </div>
        </b-alert>
        <b-row class="p-1">
          <b-col cols="12" md="6">
            <b-form-group>
              <div class="d-flex align-items-center">
                <label class="mr-1">Buscar</label>
                <b-form-input v-model="searchTerm" placeholder="Buscar" type="text" class="d-inline-block" />
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-button variant="outline-success" class="btn-icon mr-1" @click="clean">
                <feather-icon icon="MonitorIcon" /> Limpiar pantalla
              </b-button>
              <b-button variant="outline-danger" class="btn-icon mr-1" @click="downloadErrors">
                <feather-icon icon="DownloadIcon" /> Descargar errores
              </b-button>
            </div>
          </b-col>
        </b-row>
        <b-card>
          <vue-good-table 
            :columns="columns" 
            :rows="dataTable" 
            :search-options="{enabled: true, externalQuery: searchTerm}" :pagination-options="{enabled: true,perPage: pageLength}"
          >
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field === 'actions'">
                <RouterLink :to="{ path: `/coordination/assignment/type-credits/${props.row.assignment_batch_id}` }">
                  <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="btn-icon rounded-circle">
                    <feather-icon icon="EyeIcon" />
                  </b-button>
                </RouterLink>
              </span>
            </template>
            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <b-form-select v-model="pageLength" :options="['5', '10', '50', '100']" class="mx-1"
                    @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                  <span class="text-nowrap ">{{ props.total }} registros totales </span>
                </div>

                <div>
                  <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                    align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                    @input="(value) => props.pageChanged({ currentPage: value })">
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
    </div>
  </b-modal>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BCard,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BDropdownItem,
  BAlert,
  BFormGroup,
  BFormInput,
  BFormSelect,
} from 'bootstrap-vue';
import { VueGoodTable } from 'vue-good-table'
export default {
  name: 'ErrorImport',
  components: {
    BContainer,
    BRow,
    BCol,
    BCard,
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BAlert,
    VueGoodTable,
    BFormGroup,
    BFormInput,
    BFormSelect,
  },
  data(){
    return {
      columns: [
        {
          label: 'Item',
          field: 'item',
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar item',
          },
        },
        {
          label: 'Columna',
          field: 'column',
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar Columna',
          },
        },
        {
          label: 'Fila',
          field: 'row',
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar Fila',
          },
        },
        {
          label: 'Celda',
          field: 'cell',
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar celda',
          },
        },
        {
          label: 'Valor',
          field: 'value',
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar valor',
          },
        },
        {
          label: 'Error',
          field: 'error',
          filterOptions: {
            enabled: true,
            placeholder: 'Buscar error',
          },
        },
      ],
      searchTerm: '',
      pageLength: 5,
      dir: false,
      sortBy: 'code',
      isSortDirDesc: true,
      totalRows: 0,
      dataMeta: {
        from: 0,
        to: 0,
        of: 0,
      },
      dataTable: [],
      file: [],
      loadingStatus: false,
      errorStatus: false,
      formError: {
        data: [],
      }
    }
  },
  methods: {
    clean() {
      this.errorStatus = false
      this.dataTable = []
      this.$refs['modal-assignment-errors'].hide()
      this.searchTerm = ''
    },
    downloadErrors() {
      this.formError.data = this.dataTable
      this.$http.post('/coordination/assignment/download-errors', this.formError,
        { responseType: 'blob' }).then((response) => {
          this.formError.data = []
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', 'errores-asignacion.xlsx');
          document.body.appendChild(link);
          link.click();
          this.$swal({
            icon: "success",
            title: "Éxito",
            html: "Archivo descargado éxitosamente!",
          });
        })
    },
    openModalErrors() {
      this.$refs['modal-assignment-errors'].show()
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>