<template>
  <div>
    <b-card border-variant="primary" text-variant="white">
      <div>
        <b-row>
          <b-col cols="12" md="2">
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-info" @click="refreshAssigments"
              class="w-100">
              <div v-if="refreshloading">
                <b-spinner small />
              </div>
              <div v-else>
                <feather-icon icon="RefreshCwIcon" />
                Refrescar
              </div>
            </b-button>
          </b-col>
          <b-col cols="12" md="2">
            <RouterLink :to="{ path: `/coordination/assignment/type-credits-all` }">
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="info" class="w-100">
                <feather-icon icon="BookIcon" class="mr-50" />
                Ver todo
              </b-button>
            </RouterLink>
          </b-col>
          <b-col cols="12" md="4">
            <validation-observer ref="assignmentFileRulesValidation">
              <b-form>
                <validation-provider #default="{ errors }" name="archivo de asignación" rules="required">
                  <b-form-group :state="errors.length > 0 ? false : null"
                    :class="errors.length > 0 ? 'is-invalid' : null">
                    <b-form-file v-model="fileAssignment" placeholder="Cargue su asignación aquí..."
                      drop-placeholder="Arrastre su asignación aquí..." accept=".xlsx" />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>
              </b-form>
            </validation-observer>
          </b-col>
          <b-col cols="12" md="2">
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" @click="saveAssignmentFile"
              class="w-100">
              <div v-if="loadAssignmentFile">
                <b-spinner small />
              </div>
              <div v-else>
                <feather-icon icon="SunriseIcon" class="mr-50" />
                <span class="align-middle">Cargar</span>
              </div>

            </b-button>
          </b-col>
          <b-col cols="12" md="2">
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary"
              @click="openModalAssignmentBatch" class="w-100">
              <feather-icon icon="PlusCircleIcon" class="mr-50" />
              Crear Lote
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card>
      <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Search</label>
            <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
          </div>
        </b-form-group>
      </div>
      <vue-good-table :columns="columns" :rows="rows" :search-options="{ enabled: true, externalQuery: searchTerm }"
        :pagination-options="{ enabled: true, perPage: pageLength }">
        <template slot="table-row" slot-scope="props">
          <span v-if="props.column.field === 'actions'">
            <RouterLink :to="{ path: `/coordination/assignment/type-credits/${props.row.assignment_batch_id}` }">
              <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary"
              class="btn-icon rounded-circle mr-1">
              <feather-icon icon="EyeIcon" />
            </b-button>
          </RouterLink>
          <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary" @click="editLote(props.row.assignment_batch_id)"
            class="btn-icon rounded-circle">
            <feather-icon icon="EditIcon" />
          </b-button>
          
          </span>
        </template>
        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <b-form-select v-model="pageLength" :options="['4', '10', '50', '100']" class="mx-1"
                @input="(value) => props.perPageChanged({ currentPerPage: value })" />
              <span class="text-nowrap ">{{ props.total }} registros totales </span>
            </div>

            <div>
              <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength" first-number last-number
                align="right" prev-class="prev-item" next-class="next-item" class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })">
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template>
      </vue-good-table>
    </b-card>
    <!-- crear lote -->
    <b-modal ref="modal-assignment-batch" title="Creación de lote" centered hide-footer no-close-on-backdrop>
      <validation-observer ref="assignmentRulesValidation">
        <b-form>
          <validation-provider #default="{ errors }" name="calificador de calidad" rules="required">
            <b-form-group :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : null">
              <label for="qualityChecker">Calificador Calidad:</label>
              <v-select v-model="assignmentBatchForm.qualityChecker" label="name" :reduce="val => val.id"
                :options="qualityCheckerList" placeholder="Seleccionar" trim clearable />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="{ errors }" name="observación" rules="required">
            <b-form-group>
              <label for="description">Observación</label>
              <b-form-textarea v-model="assignmentBatchForm.description" id="description" rows="3" trim
                :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : null"
                placeholder="Describir asignación" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="{ errors }" name="fecha de vencimiento" rules="required">
            <b-form-group>
              <label for="dueDate">Fecha de vencimiento</label>
              <b-form-datepicker id="dueDate" v-model="assignmentBatchForm.dueDate"
                placeholder="Seleccionar fecha de vencimiento" local="es" :initial-date="today" trim
                :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="{ errors }" name="estado" rules="required">
            <b-form-group :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : null">
              <label for="qualityChecker">Estado:</label>
              <v-select v-model="assignmentBatchForm.status" label="title" :reduce="val => val.value"
                :options="statusAssignmentOptions" placeholder="Seleccionar" trim clearable />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <hr class="invoice-spacing mt-5">
          <b-form-group class="mt-2">
            <b-row>
              <b-col cols="12" md="6">
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary" block
                  @click="closeModalAssignmentBatch">
                  <feather-icon icon="XIcon" class="mr-50" />
                  <span class="align-middle">Cancelar</span>
                </b-button>
              </b-col>
              <b-col cols="12" md="6">
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" block @click="saveAssignmentBatch">
                  <feather-icon icon="CheckIcon" class="mr-50" />
                  <span class="align-middle">Guardar</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- crear lote -->
    <!-- editar lote -->

    <b-modal ref="modal-edit-assignment-batch" title="Creación de lote" centered hide-footer no-close-on-backdrop>
      <validation-observer ref="assignmentRulesValidation">
        <b-form>
          <validation-provider #default="{ errors }" name="calificador de calidad" rules="required">
            <b-form-group :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : null">
              <label for="qualityChecker">Calificador Calidad:</label>
              <v-select v-model="assignmentBatchForm.qualityChecker" label="name" :reduce="val => val.id"
                :options="qualityCheckerList" placeholder="Seleccionar" trim clearable />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="{ errors }" name="observación" rules="required">
            <b-form-group>
              <label for="description">Observación</label>
              <b-form-textarea v-model="assignmentBatchForm.description" id="description" rows="3" trim
                :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : null"
                placeholder="Describir asignación" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="{ errors }" name="fecha de vencimiento" rules="required">
            <b-form-group>
              <label for="dueDate">Fecha de vencimiento</label>
              <b-form-datepicker id="dueDate" v-model="assignmentBatchForm.dueDate"
                placeholder="Seleccionar fecha de vencimiento" local="es" :initial-date="today" trim
                :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : null" />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <validation-provider #default="{ errors }" name="estado" rules="required">
            <b-form-group :state="errors.length > 0 ? false : null" :class="errors.length > 0 ? 'is-invalid' : null">
              <label for="qualityChecker">Estado:</label>
              <v-select v-model="assignmentBatchForm.status" label="title" :reduce="val => val.value"
                :options="statusAssignmentOptions" placeholder="Seleccionar" trim clearable />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>
          <hr class="invoice-spacing mt-5">
          <b-form-group class="mt-2">
            <b-row>
              <b-col cols="12" md="6">
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-secondary" block
                  @click="closeModalAssignmentBatch">
                  <feather-icon icon="XIcon" class="mr-50" />
                  <span class="align-middle">Cancelar</span>
                </b-button>
              </b-col>
              <b-col cols="12" md="6">
                <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="primary" block @click="editAssignmentBatch()">
                  <feather-icon icon="CheckIcon" class="mr-50" />
                  <span class="align-middle">Guardar</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form-group>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- editar lote -->
    <error-import ref="ErrorImport" />
  </div>
</template>
    
<script>
import {
  BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdown, BDropdownItem, BCard, BButton, BRow, BCol, BFormFile, BFormTextarea, BFormDatepicker, BForm, BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'
import es from 'vee-validate/dist/locale/es';
import { VueGoodTable } from 'vue-good-table'
import { required } from '@validations'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import ErrorImport from './ErrorImport.vue';
export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BCard,
    BButton,
    BRow,
    BCol,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BFormTextarea,
    BFormDatepicker,
    BForm,
    localize,
    BSpinner,
    ErrorImport,
  },
  data() {
    return {
      rows: [],
      pageLength: 4,
      dir: false,
      columns: [
        {
          label: 'Número de lote',
          field: 'assignment_batch_id',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name',
          },
        },
        {
          label: 'Descripción',
          field: 'descripction',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name',
          },
        },
        {
          label: 'Estado',
          field: 'batch_status',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name',
          },
        },
        {
          label: 'Pendientes',
          field: 'total_assigned',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name',
          },
        },
        {
          label: 'Finalizados',
          field: 'total_finished',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name',
          },
        },
        {
          label: 'Total asignado',
          field: 'total_registros',
          filterOptions: {
            enabled: true,
            placeholder: 'Search Name',
          },
        },
        {
          label: 'Acciones',
          field: 'actions',
          filterOptions: {
            enabled: false,
            placeholder: 'Search Name',
          },
        },
      ],
      refreshloading: false,
      searchTerm: '',
      loadAssignmentFile: false,
      fileAssignment: null,
      qualityCheckerList: [],
      newForm: true,
      assignmentBatchForm: {
        id: null,
        description: '',
        status: null,
        qualityChecker: null,
        dueDate: null,
      },
      today: new Date(),
      statusAssignmentOptions: [
        {
          title: 'Sin Asignar',
          value: 'unassigned',
        },
        {
          title: 'Asignado',
          value: 'in_progress',
        },
        {
          title: 'Finalizado',
          value: 'finished',
        }
      ],
    }
  },
  created() {
    this.getAssignmentBatches()
    this.getQualityCheckerList()
  },
  methods: {
    editLote(id) {
      this.$refs['modal-edit-assignment-batch'].show()
      this.$http.get('/coordination/assignment/get-edit-assignment-batches/' + id).then((response) => {
       this.assignmentBatchForm.id = response.data.lote.id
       this.assignmentBatchForm.description = response.data.lote.descripction
       this.assignmentBatchForm.status = response.data.lote.status
       this.assignmentBatchForm.dueDate = response.data.lote.due_date
       this.assignmentBatchForm.qualityChecker = response.data.lote.qualityChecker
      })
    },
    getQualityCheckerList() {
      this.$http.get('/coordination/assignment/get-quality-checker').then((response) => {
        this.qualityCheckerList = response.data.qualityCheckerList
      })
    },
    getAssignmentBatches() {
      this.$http.get('/coordination/assignment/get-assignment-batches').then((response) => {
        this.rows = response.data.assignmentBatches
      })
    },
    saveAssignmentBatch() {
      localize('es', es)
      this.$refs.assignmentRulesValidation.validate().then(success => {
        if (success) {
          this.$http.post('coordination/assignment/save-assignment-batch', this.assignmentBatchForm).then((response) => {
            try {
              this.closeModalAssignmentBatch()
              this.getAssignmentBatches()
              this.$swal({
                title: response.data.message,
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
                buttonsStyling: false,
              });
            } catch (error) {
              this.$swal({
                title: error.response.data.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
                buttonsStyling: false,
              });
            }
          })
        }
      })
    },
    editAssignmentBatch() {
      localize('es', es)
      this.$refs.assignmentRulesValidation.validate().then(success => {
        if (success) {
          this.$http.post('coordination/assignment/edit-assignment-batch', this.assignmentBatchForm).then((response) => {
            try {
              this.closeModalEditAssignmentBatch()
              this.getAssignmentBatches()
              this.$swal({
                title: response.data.message,
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
                buttonsStyling: false,
              });
            } catch (error) {
              this.$swal({
                title: error.response.data.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
                buttonsStyling: false,
              });
            }
          })
        }
      })
    },
    saveAssignmentFile() {
      localize('es', es)
      this.$refs.assignmentFileRulesValidation.validate().then(success => {
        if (success) {
          this.loadAssignmentFile = true
          let formData = new FormData()
          formData.append('file', this.fileAssignment)
          this.$http.post('coordination/assignment/import-assignment', formData, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }).then((response) => {
            this.loadAssignmentFile = false
            this.getAssignmentBatches()
            this.fileAssignment = null
            this.$refs.assignmentFileRulesValidation.reset()
            this.$swal({
              title: response.data.message,
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-success',
              },
              buttonsStyling: false,
            });
          })
            .catch((error) => {
              this.fileAssignment = null
              this.loadAssignmentFile = false
              this.$refs.assignmentFileRulesValidation.reset()
              this.viewErrors(error.response.data.errors)
              this.$refs.ErrorImport.openModalErrors();
            })
        }
      })
    },
    assignmentBatchResetForm() {
      this.assignmentBatchForm = {
        id: null,
        description: '',
        status: null,
        qualityChecker: null,
        dueDate: null,
      }
    },
    closeModalAssignmentBatch() {
      this.$refs['modal-assignment-batch'].hide()
      this.$refs.assignmentRulesValidation.reset()
      this.assignmentBatchResetForm()
    },
    closeModalEditAssignmentBatch() {
      this.$refs['modal-edit-assignment-batch'].hide()
      this.$refs.assignmentRulesValidation.reset()
      this.assignmentBatchResetForm()
    },
    openModalAssignmentBatch() {
      this.$refs['modal-assignment-batch'].show()
    },
    viewErrors(errorsData) {
      this.$refs.ErrorImport.errorStatus = true
      this.$refs.ErrorImport.dataTable = errorsData
      this.$refs.ErrorImport.totalRows = errorsData.length
    },
    refreshAssigments() {
      this.refreshloading = true
      this.getAssignmentBatches()
      this.refreshloading = false
    },
  },
  directives: {
    Ripple,
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-select.scss';
@import "@core/scss/vue/libs/vue-good-table.scss";
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>